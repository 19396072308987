export default defineNuxtRouteMiddleware(async (to, from) => {
  const console = useConsole();
  const requestUrl = useRequestURL();

  console.log('🔌 MDLW:', 'catch-all', requestUrl.pathname);

  if(requestUrl.pathname.match(/^\/moto\/(wideo|galeria)\//)) {
    const articleSlug = requestUrl.pathname.replace(/^\/moto\/[^\/]+\//, '');
    const redirection = "/moto/artykul/" + articleSlug + requestUrl.search;
    return navigateTo(redirection,{redirectCode: 301});
  }

  const slugParam = to.params.slug;
  const customUrlCandidate =
    Array.isArray(slugParam) ? slugParam.join('/') :
    typeof slugParam === 'string' ? slugParam : '';

  const backend = useBackend();
  const dynamicCollection = useState('dynamicCollection', () => null);

  try {
    const response = await backend.getCollection(customUrlCandidate, 'custom_url');
    if (
      !response?.data ||
      (Array.isArray(response.data) && !response.data.length) ||
      !response.data.search
    ) {
      return navigateTo("/" + (requestUrl.search || ""),{redirectCode: 301});
    }
    dynamicCollection.value = response.data;
    return;
  } catch (err) {
    return navigateTo("/" + (requestUrl.search || ""),{redirectCode: 301});
  }
});
